<template>
  <div class="w80 py-md-10">
    <div class="row text-center dosColumnas">
      <div class="col-lg-4 col-md-4 col-sm-12 d-none d-md-block">
        <div class="register-image-checo-max">
          <img
            class="ChecoMaxRegister"
            :src="require('../../../assets/2024/MaxCheco_registro.png')"
            alt=""
          />
        </div>
      </div>
      <div class="col-lg-8 col-md-8 col-sm-12">
        <!-- <img
          class="fondoFormulario"
          src="../../../assets/FondoForm.png"
          alt=""
        /> -->
        <div class="contenedorFormDktp">
          <!-- <img class="fondoFormulario" src="../../../assets/FondoForm.png" alt=""> -->
          <h1 class="font-white text-center pt-3">
            Regístrate para participar
          </h1>
          <hr class="separador-h-home">
          <form
            action=""
            class="mt-5 w100"
            @submit.prevent="submitFormRegister"
          >
            <div class="row">
              <div class="col-md-6 col-sm-6">
                <div class="form-group mb-5">
                  <input
                    type="text"
                    class="form-control input24 font-regular"
                    id="name"
                    v-model="form.name"
                    placeholder="Nombre"
                  />
                </div>

                <div class="form-group mb-5">
                  <input
                    type="text"
                    class="form-control input24 font-regular"
                    id="last_name"
                    v-model="form.last_name"
                    placeholder="Segundo Apellido"
                  />
                </div>
                <div class="form-group mb-5">
                  <input
                    type="text"
                    class="form-control input24 font-regular"
                    id="email"
                    v-model="form.email"
                    placeholder="Correo"
                  />
                </div>
                <p class="font-white font-regular text-center">
                  Fecha de Nacimiento
                </p>
                <div class="form-group mb-5">
                  <div class="d-flex">
                    <input
                      type="text"
                      class="form-control input24 font-regular"
                      v-model="form.birthdayDay"
                      id="day"
                      placeholder="DD"
                      maxlength="2"
                      @input="focusNextInput('month', $event)"
                    />
                    <input
                      type="text"
                      class="form-control input24 font-regular"
                      v-model="form.birthdayMonth"
                      id="month"
                      placeholder="MM"
                      maxlength="2"
                      @input="focusNextInput('year', $event)"
                    />
                    <input
                      type="text"
                      class="form-control input24 font-regular"
                      v-model="form.birthdayYear"
                      id="year"
                      placeholder="AAAA"
                      maxlength="4"
                    />
                  </div>
                </div>
              </div>

              <div class="col-md-6 col-sm-6 separador-v-home">
                <div class="form-group mb-5">
                  <input
                    type="text"
                    class="form-control input24 font-regular"
                    id="first_name"
                    v-model="form.first_name"
                    placeholder="Primer Apellido"
                  />
                </div>

                <div class="form-group mb-5">
                  <input
                    type="text"
                    class="form-control input24 font-regular"
                    id="phone"
                    v-model="form.phone"
                    placeholder="Teléfono"
                  />
                </div>
                <div class="form-group mb-5">
                  <input
                    :type="showPassword5 ? 'text' : 'password'"
                    class="form-control input24 font-regular"
                    id="password"
                    v-model="form.password"
                    placeholder="Contraseña"
                  />
                  <i
                    class="fa fa-eye show-password-icon-register"
                    @click="showPassword5 = !showPassword5"
                  ></i>
                </div>
                <div class="form-group mb-5">
                  <p class="font-white font-regular">Acepto</p>
                  <div class="form-group container-tyc">
                    <div class="d-flex mb-2">
                      <input
                        class="form-check-input checkbox-tyc"
                        type="checkbox"
                        id="terms"
                        v-model="form.tyc"
                      />
                      <label
                        class="form-check-label font-white font-regular label-tyc"
                        for="terms"
                      >
                        <a
                          class="font-white font-regular lnk ms-3"
                          href="/terminos-y-condiciones"
                        >
                          Términos y condiciones</a
                        >
                      </label>
                    </div>
                  </div>
                  <div class="form-group container-tyc">
                    <div class="d-flex">
                      <input
                        class="form-check-input checkbox-tyc"
                        type="checkbox"
                        id="avprv"
                      />
                      <label
                        class="form-check-label font-white font-regular label-tyc"
                        for="terms"
                      >
                        <a
                          class="font-white font-regular lnk ms-3"
                          href="https://corporate.exxonmobil.com/Global-legal-pages/privacy-policy_mexico-spanish_marketing"
                        >
                          Aviso de privacidad</a
                        >
                      </label>
                    </div>
                  </div>
                </div>

                <!-- <div class="form-group mb-5">
                  <select class="form-control input24 mt-9" id="gender" v-model="form.gender" placeholder="Genero*">
                    <option value="">Genero*</option>
                    <option value="Masculino">Masculino</option>
                    <option value="Femenino">Femenino</option>
                  </select>
                </div> -->
              </div>
            </div>
            <div class="mt-3">
              <button
                class="btn btn-primary btn-lg btn-launch registrar-button-registro"
              >
                Registrar
              </button>
            </div>
          </form>
          <div class="d-flex justify-center mt-3 mb-5">
            <button
              class="font-white ya-tengo-cuenta-button-registro"
              @click="redirectWithTimeout('/comprueba-cuenta')"
            >
              Ya tengo cuenta de Mobil ® Racing Club
            </button>
          </div>
          <p class="tyc font-white px-8 pb-5">
            Al iniciar sesión confirmas que estás listo para disfrutar de la
            carrera con nuestros Términos y Condiciones. Conoce más detalles
            <a href="/terminos-y-condiciones" target="_blank">aquí.</a>
          </p>
        </div>
      </div>
      <!-- <img class="mobil50Y" src="../../../assets/Mobil50Y.png" alt="" /> -->
    </div>
    <!-- Solo para moviles -->
    <!-- <div class="row text-center d-sm-none">
      <div class="col-lg-4 col-md-3 col-sm-2 d-none d-md-block d-sm-none">
        <img class="MaxVReg" src="../../../assets/Max.png" alt="MaxV" />
      </div>
      <div class="col-lg-4 col-md-6 col-sm-8 col-xs-12">
        <div class="contenedor-form">
          <img
            class="headregister d-sm-none"
            src="../../../assets/registerHead.png"
            alt="Mobile Logo"
          />
          <h2 class="font-white text-center mt-10 mb-5 f30">Registro</h2>
          <form
            action=""
            class="mt-5 w100-mobile"
            @submit.prevent="submitFormRegister"
          >
            <div class="form-group mb-5">
              <input
                type="text"
                class="form-control input24"
                id="name"
                v-model="form.name"
                placeholder="Nombre*"
              />
            </div>
            <div class="form-group mb-5">
              <input
                type="text"
                class="form-control input24"
                id="first_name"
                v-model="form.first_name"
                placeholder="Primer Apellido*"
              />
            </div>
            <div class="form-group mb-5">
              <input
                type="text"
                class="form-control input24"
                id="last_name"
                v-model="form.last_name"
                placeholder="Segundo Apellido*"
              />
            </div>
            <div class="form-group mb-5">
              <input
                type="text"
                class="form-control input24"
                id="email"
                v-model="form.email"
                placeholder="Correo*"
              />
            </div>
            <div class="form-group mb-5">
              <input
                :type="showPassword6 ? 'text' : 'password'"
                class="form-control input24"
                id="password"
                v-model="form.password"
                placeholder="Contraseña*"
              />
              <i
                class="fa fa-eye password-icon-register"
                @click="showPassword6 = !showPassword6"
              ></i>
            </div>
            <div class="form-group mb-5">
              <input
                type="text"
                class="form-control input24"
                id="phone"
                v-model="form.phone"
                placeholder="Teléfono*"
              />
            </div>
            <h3 class="font-white text-center normal">Fecha de Nacimiento</h3>
            <div class="form-group mb-5">
              <div class="d-flex">
                <input
                  type="text"
                  class="form-control input24 mx-5 mt-3"
                  v-model="form.birthdayDay"
                  id="day"
                  placeholder="DD"
                  maxlength="2"
                  @input="focusNextInput('month', $event)"
                />
                <input
                  type="text"
                  class="form-control input24 mx-5 mt-3"
                  v-model="form.birthdayMonth"
                  id="month"
                  placeholder="MM"
                  maxlength="2"
                  @input="focusNextInput('year', $event)"
                />
                <input
                  type="text"
                  class="form-control input24 mx-5 mt-3"
                  v-model="form.birthdayYear"
                  id="year"
                  placeholder="AAAA"
                  maxlength="4"
                />
              </div>
            </div>
            <div class="form-group mb-5 tyc-mobile">
              <p class="font-white mx-3">Acepto</p>
              <input
                class="form-check-input"
                type="checkbox"
                id="terms"
                v-model="form.tyc"
              />
              <label class="form-check-label font-white tyc" for="terms">
                <a class="font-white lnk" href="/terminos-y-condiciones"
                  >Términos y condiciones</a
                >
              </label>
              <input class="form-check-input" type="checkbox" id="avprv" />
              <label class="form-check-label font-white tyc" for="avprv">
                <a
                  class="font-white lnk"
                  href="https://corporate.exxonmobil.com/Global-legal-pages/privacy-policy_mexico-spanish_marketing"
                  >Aviso de privacidad</a
                >
              </label>
            </div>
            <div class="d-flex justify-center">
              <button class="btn btn-primary btn-lg btn-launch registrarse">
                Registrarse
              </button>
            </div>
          </form>
          <div class="d-flex justify-center mt-3 mb-5">
            <button
              class="font-white iniciarSesion"
              @click="redirectWithTimeout('/comprueba-cuenta')"
            >
              Ya tengo cuenta de Mobil ® Racing Club
            </button>
          </div>
          <p class="tyc font-white px-8">
            Al iniciar sesión confirmas que estás listo para disfrutar de la
            carrera con nuestros Términos y Condiciones. Conoce más detalles
            <a href="/terminos-y-condiciones" target="_blank">aquí.</a>
          </p>
        </div>
      </div>
    </div> -->
    <!-- <div class="col-lg-4 col-md-3 col-sm-4 d-none d-sm-block">
      <img class="ChecoReg" src="../../../assets/Checo.png" alt="Checo">
    </div> -->
  </div>
</template>

<script src="./register.js" />
<style src="./register.scss" lang="scss" />
<style src="../../../App.scss" lang="scss"/>
<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "register",
  metaInfo: {
    title: "Mobil Predictor",
    meta: [
      { name: "title", content: "Regístrate y gana con Mobil Predictor" },
      {
        name: "description",
        content:
          "Compite con otros aficionados a la Gran Carrera con Mobil Predictor. Predice resultados, gana puntos y premios. ¡Regístrate ahora!",
      },
    ],
  },
  data() {
    return {
      form: {
        name: "",
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        phone: "",
        birthdayDay: "",
        birthdayMonth: "",
        birthdayYear: "",
        tyc: "",
      },
      showPassword5: false,
      showPassword6: false,
    };
  },
  mounted() {
    if (localStorage.getItem("predictor") && document.cookie.includes("predictor")) {
      this.$router.push("/dashboard");
    }
  },
  methods: {
    async submitFormRegister() {
      try {
        axios.defaults.headers.common["Content-Type"] = "application/json";
        if (!this.form.tyc) {
          Swal.fire({
            icon: "warning",
            title: "¡Espera!",
            text: "Debes aceptar los términos y condiciones.",
            background: "rgba(0, 20, 79, 0.8)",
            confirmButtonColor: "#ff0000",
            color: "#fff",
          });
          return;
        }
        const response = await axios.post(
          "https://api.predictor.vmtec.com.mx/api/register",
          this.form
        );
        if (response.data.code == 201) {
          this.$router.push("/doble-auth");
          localStorage.setItem("predictor", JSON.stringify(response.data));
          const expirationDate = new Date();
          expirationDate.setDate(expirationDate.getDate() + 30);
          document.cookie = `predictor=${JSON.stringify(
            response.data.email
          )}; expires=${expirationDate.toUTCString()}; path=/`;
        }
      } catch (error) {
        if (error.response.data.code == "FailData") {
          Swal.fire({
            icon: "warning",
            title: "Hay un error en tu registro.",
            text:
              "Revisa que tus datos estén completos, que tu contraseña sea de mínimo 8 dígitos (con mayúsculas, minúsculas y números) y que tu teléfono sea de 10 dígitos.",
              background: "rgba(0, 20, 79, 0.8)",
              confirmButtonColor: "#ff0000",
            color: "#fff",
          });
        }

        if (error.response.data.code == "UserExist") {
          Swal.fire({
            icon: "info",
            title: "¡Ups!",
            text: "Tu cuenta de correo ya se encuentra registrado.",
            background: "rgba(0, 20, 79, 0.8)",
            confirmButtonColor: "#ff0000",
            color: "#fff",
          });
        }

        if (error.response.data.code == "EmailExistSF") {
          Swal.fire({
            icon: "info",
            title: "¡Ups!",
            text:
              "Encontramos que tu correo se encuentra registrado en Mobil ® Racing Club, te redireccionaremos para continuar con tu registro. ¡Gracias!",
              background: "rgba(0, 20, 79, 0.8)",
              confirmButtonColor: "#ff0000",
            color: "#fff",
          });
          setTimeout(() => {
            this.$router.push("/comprueba-cuenta");
          }, 3000);
        }

        if (error.response.data.code == "InvalidDomain") {
          Swal.fire({
            icon: "warning",
            title: "¡Espera!",
            text:
              "Parece que tu correo no pertenece a un dominio conocido. Por favor, verifica tu correo y vuelve a intentar. ¡Gracias!",
              background: "rgba(0, 20, 79, 0.8)",
              confirmButtonColor: "#ff0000",
            color: "#fff",
          });
        }
      }
    },
    focusNextInput(nextInput, event) {
      // Obtener el input actual y su valor
      const currentInput = event.target;
      const currentValue = currentInput.value;

      // Si el valor actual tiene la longitud máxima
      if (currentValue.length === currentInput.maxLength) {
        // Encontrar el siguiente input
        const nextInputEl = document.getElementById(nextInput);
        if (nextInputEl) {
          // Enfocar el siguiente input
          nextInputEl.focus();
        }
      }
    },
    redirectWithTimeout(address) {
      setTimeout(() => {
        window.location.href = address;
      }, 1500);
    },
  },
};
</script>

<template>
  <div class="w80-crpwd my-20">
    <div class="row text-center">
      <div class="col-lg-6 col-md-8 col-sm-12 col-xs-12">
        <div class="contenedor-form-buscarCuenta">
          <!-- <img class="headregisterBC d-sm-none" src="../../../assets/registerHead.png" alt="Mobile Logo"> -->
          <h1 class="font-white font-bold buscarCuenta-titulo">Bienvenido de nuevo a Mobil Predictor</h1>
          <p class="font-white font-regular buscarCuenta-texto mt-5 mb-5 ">Acelera hacia la emoción e inicia sesión en tu cuenta
            para disfrutar de la experiencia de predicción más emocionante de la Gran Carrera.</p>
          <p class="font-white font-bold buscarCuenta-segundo-titulo mb-5">Iniciar Sesión</p>
          <form action="" class="w100" @submit.prevent="submitFormBuscarCuenta">
            <div class="form-group mb-5 BC">
              <input type="email" class="form-control input24" id="email" v-model="form.email" placeholder="Correo">
            </div>
            <!-- <div class="form-group mb-5">
              <input type="text" class="form-control input24" id="password" placeholder="Confirmar contraseña*">
                </div> -->
            <div class="d-flex justify-center mt-10">
              <button class="btn btn-primary btn-lg btn-launch-crpwd registrarse">¡Inicia sesión
                ahora mismo!</button>
            </div>
          </form>
          <!-- <a class="font-white mt-5" href="">Olvidaste tu contraseña</a> -->
        </div>
      </div>
      <div class="col-lg-6 col-md-4 col-sm-12 col-xs-12 d-none d-sm-block">
        <img class="ChecoMaxBuscarCuenta" src="../../../../src/assets/2024/ChecoMaxMobil.png" alt="ChecoMaxMobil">
      </div>
    </div>
  </div>
</template>

<script src="./buscarCuenta.js" />
<style src="./buscarCuenta.scss" lang="scss" />
<style src="../../../App.scss" lang="scss"/>
<script>
import axios from 'axios';
import swal from 'sweetalert2';

export default {
  name: 'buscarCuenta',
  metaInfo: {
    title: 'Comprueba tu cuenta - Mobil Predictor',
    meta: [
      { name: 'title', content: 'Comprueba tu cuenta - Mobil Predictor' },
      { name: 'description', content: 'Comprueba tu cuenta de Mobil Predictor y empieza a jugar ya. Únete al Club de fans Mobil Racing Club y ¡compite por premios!' }
    ]
  },
  data() {
    return {
      form: {
        email: ''
      },
    };
  },
  methods: {
    async submitFormBuscarCuenta() {
      try {
        axios.defaults.headers.common['Content-Type'] = 'application/json';
        const response = await axios.post(
          'https://api.predictor.vmtec.com.mx/api/buscarCuentaSF',
          this.form
        );
        // console.log(response.data.result)

          if (response.data.result == 'userExists'){
            swal.fire({
              icon: 'info',
              title: '¡Ups!',
              text: 'El correo que ingresaste ya está registrado en nuestra base de datos. Seras redireccionado al inicio de sesión.',
              background: "rgba(0, 20, 79, 0.8)",
              confirmButtonColor: "#ff0000",
              color: "#fff",
            });
            setTimeout(() => {
              this.$router.push('/iniciar-sesion');
            }, 3000);
          }else{
            this.$router.push('/crear-contrasena/' + response.data.result);
          }

          if (response.data.result == "NoUserSF") {
          swal.fire({
              icon: 'info',
              title: '¡Ups!',
              text: 'No pudimos encontrar tu cuenta registrada en Mobil Racing Club. Tre redireccionaremos a la pantalla de registro.',
              background: "rgba(0, 20, 79, 0.8)",
              confirmButtonColor: "#ff0000",
              color: "#fff",
            });
        }
      } catch (error) {
        // console.error(error.response.data.result);
        if (error.response.data.result == "NoUserSF") {
          swal.fire({
              icon: 'info',
              title: '¡Ups!',
              text: 'No pudimos encontrar tu cuenta registrada en Mobil Racing Club. Tre redireccionaremos a la pantalla de registro.',
              background: "rgba(0, 20, 79, 0.8)",
              confirmButtonColor: "#ff0000",
              color: "#fff",
            });
        }
      }
    },
  },
}
</script>

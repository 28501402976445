<template>
  <div class="w80-crpwd my-10">
    <div class="row">
      <div class="col-lg-2 col-md-4 d-none d-md-block d-sm-none">
        <div class="prediccionesMobil-container-texto">
          <h1 class="font-white font-regular prediccionesMobil-titulo">
            Predicciones
          </h1>
          <p class="font-white font-regular prediccionesMobil-texto">
            Es tu turno de pisar el acelerador.<br />Añade tus avances y
            pronosticos para la proxima Gran Carrera
          </p>
        </div>
        <p class="font-white text-home-brand prediccionesMobil-brand italica">
          Powered by Mobil<sup>®</sup> Racing Club
        </p>
      </div>
      <div class="col-lg-4 d-none d-lg-block d-sm-none text-center">
        <img class="prediccionesMobil" src="../../../../../src/assets/2024/ChecoMaxMobil.png" alt="MaxV" />
      </div>
      <div class="col-lg-6 col-md-8 col-sm-12 col-xs-12">
        <div class="contenedor-Mobilpredicciones text-center py-1">
          <!-- <img class="headregister d-sm-none" src="../../../../../assets/headPredicciones.png" alt="Mobile Logo" /> -->
          <h1 class="font-white f40 mt-5">Gran premio de Bélgica</h1>
          <!-- Botones de Checo y Max -->
          <div class="container-botones-predicciones">
            <div class="">
              <button @click="alertaCambioPaginaPilotos" class="piloto font-white font-regular font-regular opacity">
                Pilotos
              </button>
            </div>
            <div class="boton-secundario">
              <a href="/predicciones-pilotos-verstappen" class="piloto font-white page-predicciones">Mobil</a>
            </div>
          </div>
          <!-- Seccion de predicciones -->
          <div class="predicciones">
            <p class="font-white f40 mt-5" v-if="isFormVisible">Predicciones no disponibles, se abren el lunes antes de
              la carrera.</p>
            <!-- <p class="font-white f40 mt-5" v-if="!showQuestions">
              Predicciones no disponibles, se abren el lunes antes de la
              carrera.
            </p> -->
            <p class="font-white f40 mt-5" v-if="!isFormVisible">Nota: En las preguntas con opciones, coloca la letra de
              la opción.</p>
            <form class="" action="" @submit.prevent="submitFormQuestionsMobil" v-if="!isFormVisible">
              <!-- <form
              class=""
              action=""
              @submit.prevent="submitFormQuestionsMobil"
              v-if="showQuestions"
            > -->
              <!-- PREGUNTAS MANUALES -->
              <!-- Pregunta 1 -->
              <h3 class="pregunta font-white font-regular mt-3">
                {{ form[0].pregunta1 }}
              </h3>
              <div class="d-flex justify-between mt-3">
                <select class="input-predicciones" v-model="form[0].respuesta1">
                  <option v-for="option in form[0].options" :key="option.id" :value="option.id">{{ option.text }}
                  </option>
                </select>
                <p class="font-white font-regular score mt-3">50 Puntos</p>
              </div>
              <!-- Fin de pregunta 1 -->
              <!-- Pregunta 2 -->
              <h3 class="pregunta font-white font-regular mt-3">
                {{ form[1].pregunta2 }}
              </h3>
              <div class="d-flex justify-between mt-3">
                <select class="input-predicciones" v-model="form[1].respuesta2">
                  <option v-for="option in form[1].options" :key="option.id" :value="option.id">{{ option.text }}
                  </option>
                </select>
                <p class="font-white font-regular score mt-3">50 Puntos</p>
              </div>
              <!-- Fin de pregunta 2 -->
              <div class="d-flex justify-center my-5">
                <!-- <button class="btn btn-primary btn-lg btn-launch-predicciones">
                  Enviar
                </button> -->
                <button class="btn btn-lg"
                  :class="{ 'btn-launch-predicciones': isFormComplete, 'btn-launch-predicciones-disabled': !isFormComplete }"
                  :disabled="!isFormComplete">
                  Guardar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script src="./mobil.js" />
<style src="../pilotos/pilotos.scss" lang="scss" />
<style src="./mobil.scss" lang="scss" />
<style src="../../../../App.scss" lang="scss" />
<!-- <style src="../predicciones/predicciones.scss" lang="scss" />  -->

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "mobil",
  metaInfo: {
    title: "Demuestra tus conocimientos sobre Mobil | Mobil Predictor",
    meta: [
      {
        name: "title",
        content: "Demuestra tus conocimientos sobre Mobil | Mobil Predictor",
      },
      {
        name: "description",
        content:
          "¡Entra a Mobil Predictor para realizar tu predicción y demostrar tu conocimiento sobre nuestra marca y pilotos! Llena los campos y gana puntos",
      },
    ],
  },
  data() {
    return {
      questions: [],
      form: [
        {
          pregunta1:
            "¿Qué servicios/secciones incluye nuestra nueva Mobil App?",
          respuesta1: "",
          options: [
            {
              id: "A",
              text: "A) Mobil Racing Club, Reto Mobil y Tienda Oficial Mobil",
            },
            {
              id: "B",
              text: "B) Facturación,Tienda Oficial Mobil, Mobil Racing Club",
            },
            { id: "C", text: "C) Directorio, Reto Mobil, Mobil Racing Club" },
            // { id: 'D', text: "D) 7 años" },
          ],
        },
        {
          pregunta2: "¿Cuáles son 3 lubricantes para motor Mobil®?",
          respuesta2: "",
          options: [
            {
              id: "A",
              text: "A) Mobil Poligrado, Mobil Synthetic Care, Mobil Lubes PRO",
            },
            {
              id: "B",
              text:
                "B) Mobil Super TRC PRO, Mobil 1, Mobil Super Anti-Friction",
            },
            {
              id: "C",
              text: "C) Mobil Extra Synthetic, Mobil MCO Pro, Mobil Super Care",
            },
            // { id: 'D', text: "D) Mobil Moto Super" },
          ],
        },
      ],
      showQuestions: true,
      isFormVisible: true,
    };
  },
  methods: {
    submitFormQuestionsMobil() {
      const predictor = JSON.parse(localStorage.getItem("predictor"));
      const email = predictor.email;

      // Recuperar el objeto existente de localStorage
      let predicciones = localStorage.getItem("predicciones");
      // Parsear el objeto JSON recuperado, o inicializar un objeto vacío si no existe
      predicciones = predicciones ? JSON.parse(predicciones) : {};
      // Agregar el nuevo valor al objeto
      predicciones.Mobil = {
        ...this.form,
        email: email
      };
      // Guardar el objeto modificado de nuevo en localStorage
      localStorage.setItem("predicciones", JSON.stringify(predicciones));

      let prediccionesFinales = JSON.parse(
        localStorage.getItem("predicciones")
      );

      // Envía los datos a la API
      axios
        .post(
          "https://api.predictor.vmtec.com.mx/api/predicciones",
          prediccionesFinales
        )
        .then((response) => {
          // console.log(response);

          // Borrar el objeto predicciones del LocalsStorage
          localStorage.removeItem("predicciones");

          document.cookie =
            "PrediccionesEnviadas=true; expires=" +
            new Date(Date.now() + 3 * 24 * 60 * 60 * 1000).toUTCString();

          setTimeout(() => {
            this.$router.push("/exito").catch(err => {
              if (err.name !== 'NavigationDuplicated') {
                throw err;
              }
            });
          }, 1000);
        })
        .catch((error) => {
          // console.log(error);
          Swal.fire({
            title: "¡Espera!",
            text:
              "Envíanos tus respuestas completas para poder participar en el predictor y conseguir más puntos.",
            icon: "info",
            confirmButtonText: "Volver a intentar",
            background: "rgba(0, 20, 79, 0.8)",
            confirmButtonColor: "#ff0000",
            color: "#fff",
          });
        });
    },
    alertaCambioPaginaPilotos() {
      if (document.cookie.indexOf("respuestasMobil=mobil") === -1) {
        Swal.fire({
          title: "¡Espera!",
          text:
            "Recuerda que debes volver aquí, para completar el registro de tus predicciones.",
          icon: "info",
          confirmButtonText: "Ok",
          background: "rgba(0, 20, 79, 0.8)",
          confirmButtonColor: "#ff0000",
          color: "#fff",
        });

        setTimeout(() => {
          this.$router.push("/predicciones-pilotos-checo");
        }, 1500);
      } else {
        this.$router.push("/predicciones-pilotos-checo");
      }
    },
  },
  computed: {
    isFormComplete() {
      return this.form.every(item => {
        return Object.values(item).every(value => value !== "");
      });
    },
  },
  watch: {
    isFormComplete(newVal) {
      console.log('isFormComplete:', newVal);
    }
  },
  mounted() {
    if (!localStorage.getItem('predictor')) {
      this.$router.push('/iniciar-sesion');
    }

    const cookieExists = document.cookie.includes("PrediccionesEnviadas");
    this.isFormVisible = cookieExists;
  },
};
</script>

import { render, staticRenderFns } from "./pilotosMax.vue?vue&type=template&id=4157873f&"
import script from "./pilotosMax.vue?vue&type=script&lang=js&"
export * from "./pilotosMax.vue?vue&type=script&lang=js&"
import style0 from "../pilotos.scss?vue&type=style&index=0&prod&lang=scss&"
import style1 from "../../../../../App.scss?vue&type=style&index=1&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports